<template>
    <div class="incubator-final-complete">
        <div class="status">
            <img
                class="status-img"
                src="../../assets/register/success.png"
                alt=""
            >
            <div class="text">
                <div style="color: #61c88a">
                    {{ $t('IncubatorFinal.Complete.text[0]') }}
                </div>
                <div>
                    {{ $t('IncubatorFinal.Complete.text[1]') }}
                    <br>
                    {{ $t('IncubatorFinal.Complete.text[2]') }}
                </div>
                <div style="margin-top:20px;">
                    {{ $t('IncubatorFinal.Complete.text[3]') }}
                </div>
            </div>
        </div>
        <div class="action">
            <div
                class="btn white-btn"
                @click="backToHome"
            >
                {{ $t('IncubatorFinal.Complete.button[0]') }}
            </div>
            <div
                class="btn orange-btn"
                @click="toProfile"
            >
                {{ $t('IncubatorFinal.Complete.button[1]') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {
        applyId() {
            return this.$route.params.id;
        },
    },
    created() { },
    mounted() { },
    methods: {
        backToHome() {
            this.$router.push({ name: 'Home' });
        },
        toProfile() {
            this.$router.push({ name: 'Profile' });
        },
    },
};
</script>

<style lang="scss" scoped>
.incubator-final-complete {
  .status {
    text-align: center;
    margin-top: 70px;
  }
  .status-img {
    width: 120px;
    margin-bottom: 15px;
  }

  .text {
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 2;
    letter-spacing: 0.1em;
    margin-bottom: 32px;
  }

  .action{
    display: flex;
    justify-content: center;
    margin-top: 80px;
    .btn {
      width: 285px;
      padding: 21px;
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.1em;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
    }
    .btn:not(:last-child){
      margin-right: 30px;
    }
  }
}
</style>
